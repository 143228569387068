@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

html {
  @apply pb-5;
}

.link__gradient {
  @apply  bg-gradient-to-r from-rose-300 via-pink-400 to-indigo-400 bg-[length:0%_3px] transition-all ease-in-out duration-200 hover:bg-[length:100%_3px] bg-no-repeat bg-bottom;
}
